.App {
  text-align: center;
  margin-top: 50px;
}

body {
  background-color: rgb(105, 105, 103);
  padding: 15px;
  text-align: center;
}

.navbar {
  display: flex;
  justify-content: space-around;
  background-color: rgb(213, 255, 196);
  padding: 10px;
  margin-top: 20px;
  border-radius: 25px;
}

.nav-item {
  display: flex;
  gap: 10px;
}

button {
    background-color: rgb(241, 250, 116);
    font-size: 16px;
    font-weight: bold;
    color: rgb(0, 0, 0);
    border-radius: 8px;
    padding: 8px;
    margin: 5px;
}

button:hover {
  background-color: #5fcf64;
}

h1{
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(78, 190, 153);
  background-color: rgb(97, 27, 161);
  padding: 16px;
  text-align: center;
  border-radius: 25px;
}

.center-box {
  background-color: lightgray;
  width: 1050px;
  height: 600px;
  margin: 20px auto; 
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 25px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); 
}

input {
  padding: 8px;
  font-size: 17px;
  margin-right: 10px;
  border-radius: 15px;
  border: 1px solid #ccc;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  margin: 5px 0;
  padding: 10px;
  background-color: #eca3a3;
  border-radius: 15px;
  font-size: 20px; 
  font-weight: large;
}

.inventory-list {
  list-style-type: none;
  padding: 0;
}

.inventory-input {
  margin-left: 0px; 
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ddd;
}

.inventory-button {
  margin-left: 10px; 
  background-color: rgb(241, 250, 116);
  font-size: 14px;
  font-weight: bold;
  color: black;
  border-radius: 8px;
  padding: 4px 8px;
}


.inventory-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  border: 1px solid #070707;
  margin-bottom: 6px;
  margin-left: 220px;
  margin-right: 220px;
  border-radius: 15px;
}

.inventory-item button {
  background-color: rgb(241, 250, 116);
  font-size: 14px;
  font-weight: bold;
  color: black;
  border-radius: 8px;
  padding: 4px 8px;
  margin-left: 10px;
}

.tracker-list {
  list-style-type: none;
  padding: 0;
}

.task-input input {
  margin-left: 0px; 
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ddd;
}

.tracker-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  border: 1px solid #070707;
  margin-bottom: 6px;
  margin-left: 220px;
  margin-right: 220px;
  border-radius: 15px;
}

.tracker-item button {
  background-color: rgb(241, 250, 116);
  font-size: 14px;
  font-weight: bold;
  color: black;
  border-radius: 8px;
  padding: 4px 8px;
  margin-left: 5px;
}

.projects-list {
  list-style-type: none;
  padding: 0;
}

.project-input {
  margin-left: 0px; 
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ddd;
}

.project-item {
  display: flex;
  flex-direction: column; /* Stack input fields vertically */
  padding: 8px;
  border: 1px solid #070707;
  margin-bottom: 6px;
  margin-left: 220px;
  margin-right: 220px;
  border-radius: 15px;
}

.project-item input {
  margin-bottom: 8px;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ddd;
}

.project-item button {
  background-color: rgb(241, 250, 116);
  font-size: 14px;
  font-weight: bold;
  color: black;
  border-radius: 8px;
  padding: 4px 8px;
  margin-left: 5px;
}